import React from 'react';

import { type Organization, OrganizerRoleUtils } from '../../types';
import { useUser } from '../UserContext';

export function OrgRequired(props: {
  children?: ((org: Organization) => JSX.Element) | React.ReactNode;
}): JSX.Element | null {
  const user = useUser();

  if (!user.organizer?.organization)
    return (
      <div className='w-full h-full flex items-center justify-center text-white'>
        This is only available to the organization members
      </div>
    );

  if (props.children instanceof Function) {
    return props.children(user.organizer.organization);
  }
  return <>{props.children}</>;
}

export function OrgAdminRequired(props: {
  children: (organization: Organization) => JSX.Element;
}) {
  const user = useUser();

  return (
    <OrgRequired>
      {(org) =>
        OrganizerRoleUtils.isOwnerOrAdmin(user.organizer) ? (
          props.children(org)
        ) : (
          <div className='w-full h-full flex items-center justify-center text-white'>
            This is only available to the admins.
          </div>
        )
      }
    </OrgRequired>
  );
}
